export var ConsentStatusLabel;
(function (ConsentStatusLabel) {
    ConsentStatusLabel["REQUIRED"] = "requis";
    ConsentStatusLabel["ACTIVATED"] = "Activ\u00E9s";
    ConsentStatusLabel["DISABLED"] = "D\u00E9sactiv\u00E9s";
})(ConsentStatusLabel || (ConsentStatusLabel = {}));
export var CSSPosition;
(function (CSSPosition) {
    CSSPosition[CSSPosition["LEFT_NINE"] = 9] = "LEFT_NINE";
    CSSPosition[CSSPosition["LEFT_THREE"] = 3] = "LEFT_THREE";
    CSSPosition[CSSPosition["LEFT_SEVEN"] = 11] = "LEFT_SEVEN";
})(CSSPosition || (CSSPosition = {}));
export var TableHeadCellOfConsent;
(function (TableHeadCellOfConsent) {
    TableHeadCellOfConsent["COOKIE_NAME"] = "Nom de cookies";
    TableHeadCellOfConsent["Purpose"] = "Finalit\u00E9";
    TableHeadCellOfConsent["DURATION_OF_THE_CONVERSATION"] = "Dur\u00E9e de conservation";
})(TableHeadCellOfConsent || (TableHeadCellOfConsent = {}));
