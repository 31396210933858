var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box } from "@mui/material";
import { styled } from "@mui/system";
export var CookiesWrapperStyle = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  position: fixed;\n  border-top: 2px solid;\n  border-color: ", ";\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 550;\n  \"@media print\" {\n    display: none;\n  }\n  & .cookies-banner {\n    position: relative;\n    margin: 0 auto;\n    max-width: 1100px;\n\n    ", " {\n      padding: ", ";\n    }\n    ", " {\n      padding: ", ";\n    }\n    ", " {\n      padding: ", ";\n    }\n  }\n"], ["\n  background-color: ", ";\n  position: fixed;\n  border-top: 2px solid;\n  border-color: ", ";\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 550;\n  \"@media print\" {\n    display: none;\n  }\n  & .cookies-banner {\n    position: relative;\n    margin: 0 auto;\n    max-width: 1100px;\n\n    ", " {\n      padding: ", ";\n    }\n    ", " {\n      padding: ", ";\n    }\n    ", " {\n      padding: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.common.consent.wrap.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.common.consent.wrap.borderColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.up("md");
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.down("sm");
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.down("xs");
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(0.5);
});
var templateObject_1;
