import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useContext, useEffect } from "react";
import PageVersionContext from "../shared/PageVersionContext";
var MatomoCMSPageTracking = function () {
    var currentPageVersion = useContext(PageVersionContext).currentPageVersion;
    var _a = currentPageVersion || {}, id = _a.id, title = _a.title;
    var trackPageView = useMatomo().trackPageView;
    useEffect(function () {
        if (title) {
            trackPageView({ documentTitle: title });
        }
    }, [id, title, trackPageView]);
    return null;
};
export default MatomoCMSPageTracking;
