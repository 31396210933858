var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import useEnvironment from "cms/hooks/useEnvironment";
import { useEffect, useState } from "react";
export var MatomoContextProvider = function (_a) {
    var children = _a.children, isBo = _a.isBo;
    var environment = useEnvironment();
    var _b = useState(null), instance = _b[0], setInstance = _b[1];
    useEffect(function () {
        if (!isBo && environment && environment.matomo_track_url && environment.matomo_site_id) {
            var options = {
                urlBase: environment.matomo_track_url,
                siteId: Number(environment.matomo_site_id),
                configurations: {
                    disableCookies: true
                }
            };
            setInstance(createInstance(options));
        }
    }, [isBo, environment]);
    if (!instance) {
        return children;
    }
    return _jsx(MatomoProvider, __assign({ value: instance }, { children: children }), void 0);
};
