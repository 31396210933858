import useEnvironment from "cms/hooks/useEnvironment";
import CookiesConsentService, { COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME } from "cms/services/CookiesConsentService";
import { useCallback } from "react";
import { useCookiesContext } from "../CookiesContext";
export var useCookiesBanner = function () {
    var _a = useCookiesContext(), handleCookiesModalContext = _a.handleCookiesModal, openCookiesModal = _a.openCookiesModal, isDisabledBanner = _a.isDisabledBanner, disabledBanner = _a.disabledBanner, setGoogleRecaptchaConsent = _a.setGoogleRecaptchaConsent;
    var environment = useEnvironment();
    var handleCookiesModal = useCallback(function () {
        if (openCookiesModal && !isDisabledBanner) {
            disabledBanner();
        }
        handleCookiesModalContext();
    }, [openCookiesModal, isDisabledBanner, handleCookiesModalContext, disabledBanner]);
    var handleRefuseAll = useCallback(function () {
        if (environment === null || environment === void 0 ? void 0 : environment.recaptcha_site_key_invisible) {
            CookiesConsentService.refuseCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME);
            setGoogleRecaptchaConsent(false);
        }
        disabledBanner();
    }, [environment, disabledBanner, setGoogleRecaptchaConsent]);
    var handleAcceptAll = useCallback(function () {
        if (environment === null || environment === void 0 ? void 0 : environment.recaptcha_site_key_invisible) {
            CookiesConsentService.acceptCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME);
            setGoogleRecaptchaConsent(true);
        }
        disabledBanner();
    }, [environment, disabledBanner, setGoogleRecaptchaConsent]);
    return {
        isDisabledBanner: isDisabledBanner,
        handleCookiesModal: handleCookiesModal,
        handleRefuseAll: handleRefuseAll,
        handleAcceptAll: handleAcceptAll
    };
};
