var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormHelperText, Grid, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ConsentStatusLabel, CSSPosition, TableHeadCellOfConsent } from "cms/types/cookies.entity";
import { useEffect, useState } from "react";
var CookiesTable = function (_a) {
    var cookiesRows = _a.cookiesRows, checked = _a.checked, _b = _a.onChange, onChange = _b === void 0 ? function () { return undefined; } : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.description, description = _d === void 0 ? null : _d, subtitle = _a.subtitle, switchLabel = _a.switchLabel;
    var TableHeadCells = __spreadArray([], Object.values(TableHeadCellOfConsent), true);
    var _e = useState(), switchStateLabel = _e[0], setSwitchStateLabel = _e[1];
    var _f = useState(CSSPosition.LEFT_NINE), labelLeftPosition = _f[0], setLabelLeftPosition = _f[1];
    useEffect(function () {
        if (disabled) {
            setSwitchStateLabel(ConsentStatusLabel.REQUIRED);
            setLabelLeftPosition(CSSPosition.LEFT_SEVEN);
        }
        else if (checked) {
            setSwitchStateLabel(ConsentStatusLabel.ACTIVATED);
            setLabelLeftPosition(CSSPosition.LEFT_NINE);
        }
        else {
            setSwitchStateLabel(ConsentStatusLabel.DISABLED);
            setLabelLeftPosition(CSSPosition.LEFT_THREE);
        }
    }, [checked, disabled]);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true, alignItems: "center", wrap: "nowrap" }, { children: [_jsxs(Grid, __assign({ item: true, xs: "auto", md: 1, sx: { position: "relative" } }, { children: [_jsx(Switch, { checked: checked, onChange: onChange, disabled: disabled }, void 0), _jsx(FormHelperText, __assign({ sx: { position: "absolute", bottom: -10, left: labelLeftPosition } }, { children: switchLabel || switchStateLabel }), void 0)] }), void 0), _jsx(Typography, __assign({ component: "span", variant: "h6" }, { children: subtitle }), void 0)] }), void 0), description && (_jsx(Grid, __assign({ container: true, justifyContent: "flex-end", sx: { mt: "12px" } }, { children: _jsx(Grid, __assign({ item: true, xs: 12, md: 11 }, { children: _jsx(Typography, __assign({ component: "span", variant: "body2" }, { children: description }), void 0) }), void 0) }), void 0)), cookiesRows && (_jsx(Grid, __assign({ container: true, justifyContent: "flex-end" }, { children: _jsx(Grid, __assign({ item: true, xs: 12, md: 11 }, { children: _jsx(TableContainer, { children: _jsxs(Table, __assign({ "aria-label": "Description des cookies" }, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: TableHeadCells.map(function (cell) { return (_jsx(TableCell, { children: cell }, cell)); }) }, void 0) }, void 0), _jsx(TableBody, { children: cookiesRows.map(function (row) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: row.name }, void 0), _jsx(TableCell, { children: row.finality }, void 0), _jsx(TableCell, { children: row.conservation }, void 0)] }, row.id)); }) }, void 0)] }), void 0) }, void 0) }), void 0) }), void 0))] }, void 0));
};
export default CookiesTable;
